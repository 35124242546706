import { createStore } from 'redux';
import SubjectList from '../components/SubjectList';

const initialState = {
  accessLevel: '',
  isLoggedIn: false,
  cart: [],
  name: '',
  phoneNo: '',
  email :'',
  OrderId:'',
  SubjectList:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      const loginState = {
        ...state,
        isLoggedIn: true,
        accessLevel: action.payload.accessLevel,
        name: action.payload.name,
        email: action.payload.email,
        phoneNo: action.payload.phoneNo,
        sessionId : action.payload.sessionId
      };
      localStorage.setItem('appState', JSON.stringify(loginState));
      return loginState;

    case 'LOGOUT':
      const logoutState = {
        ...state,
        isLoggedIn: false,
        accessLevel: '',
      };
      localStorage.setItem('appState', JSON.stringify(logoutState));
      return logoutState;

      case 'SAVE_SUBJECTS':
      const saveSubjectsState = {
        ...state,
        SubjectList: action.payload.subjects,
      };
      localStorage.setItem('appState', JSON.stringify(saveSubjectsState));
      return saveSubjectsState;

      case 'ORDER_ID':
        const orderIdState = {
          ...state,
          OrderId: action.payload.OrderId,
        };
        localStorage.setItem('appState', JSON.stringify(orderIdState));
        return orderIdState;

      case 'UPDATE_SUBJECT_LIST':
        const updatedSubjectListState = {
          ...state,
          SubjectList: action.payload.SubjectList,
        };
        localStorage.setItem('appState', JSON.stringify(updatedSubjectListState));
        return updatedSubjectListState;


      case 'ADD_TO_CART':
        const itemExists = state.cart.some((item) => item.name === action.payload.item.name);

        if (itemExists) {
          console.log('Item already exists in cart:', action.payload.item.name);
          return state;
        }
        const updatedCart = [...state.cart, action.payload.item];
        const updatedState = {
          ...state,
          cart: updatedCart,
        };
        localStorage.setItem('appState', JSON.stringify(updatedState));
        return updatedState;

        case 'REMOVE_FROM_CART':
          const filteredCart = state.cart.filter((_, index) => index !== action.payload.index);
          const newState = {
            ...state,
            cart: filteredCart,
          };
          localStorage.setItem('appState', JSON.stringify(newState));
          return newState;
  
          default:
            const storedState = localStorage.getItem('appState');
            return storedState ? JSON.parse(storedState) : state;
        }
};

const store = createStore(reducer);

export default store;
