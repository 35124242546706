import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation,useParams } from 'react-router-dom';
import './TransactionDetails.css';

const TransactionDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const { orderId } = useParams()
  // Get the orderId from the URL query parameters
  const params = new URLSearchParams(location.search);
  // const orderId = params.get('orderId');

  const email = useSelector((state) => state.email);
  const phoneNo = useSelector((state) => state.phoneNo);
  const name = useSelector((state) => state.name);

  const apiUrl = `https://www.mbascribbles.com:8443/status?orderId=${orderId}`;

  // Move the check for orderId inside the effect to prevent hook call conditionally
  useEffect(() => {
    if (!orderId) {
      setError('Order ID is not available.');
      setLoading(false);
      return; // early return if orderId is not available
    }

    const fetchTransactionDetails = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);

        if (data.results.results.status === "Success" && data.results && data.results.results) {
          const productNames = data.results.results.product_name;
          dispatch({
            type: 'ADD_SUBJECTS_TO_LIST',
            payload: productNames.split(', '),
          });
          await updateUserInfo(productNames.split(', '));

          setTransaction(data.results.results);
        } else {
          setError("No valid transaction details found.");
        }
      } catch (error) {
        setError("Error fetching data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [orderId, dispatch, apiUrl]); // Ensure apiUrl is included in dependencies

  const updateUserInfo = async (productNames) => {
    try {
      const response = await fetch('https://www.mbascribbles.com:8443/updateUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          subjects: productNames,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error updating user info: ${response.status}`);
      }

      const data = await response.json();
      console.log('User info updated successfully:', data);
    } catch (error) {
      console.error('Failed to update user info:', error.message);
    }
  };

  if (loading) {
    return <div className="loading">Loading transaction details...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="transaction-container">
      <h3>Transaction Details</h3>
      {transaction ? (
        <div className="transaction-details">
          <p><strong>Transaction ID:</strong> {transaction.txn_id}</p>
          <p><strong>Order ID:</strong> {transaction.order_id}</p>
          <p><strong>Merchant Name:</strong> {transaction.merchant_name}</p>
          <p><strong>Transaction Date:</strong> {transaction.txn_date}</p>
          <p><strong>Transaction Amount:</strong> {transaction.txn_amount}</p>
          <p><strong>Status:</strong> {transaction.status}</p>
          <p><strong>Product Name:</strong> {transaction.product_name}</p>
          <p><strong>Customer Name:</strong> {transaction.customer_name}</p>
          <p><strong>Customer Mobile:</strong> {transaction.customer_mobile}</p>
          <p><strong>Customer Email:</strong> {transaction.customer_email}</p>
        </div>
      ) : (
        <p>No transaction details available.</p>
      )}
    </div>
  );
};

export default TransactionDetails;
