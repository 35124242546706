import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SubjectList from '../components/SubjectList';
import { useSelector } from 'react-redux';
import '../App.css';

function Subjects() {
  const { courseName, year, price } = useParams(); // Destructure both params
  const [subjects, setSubjects] = useState([]);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const accessLevel = useSelector((state) => state.accessLevel);

  const fetchSubjects = async () => {
    try {
      const url = new URL('https://www.mbascribbles.com:8443/getSubject');
      url.searchParams.append('course', courseName);
      url.searchParams.append('year', year);
      url.searchParams.append('price', price);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Fetching URL:', url);

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched Subjects:', data);
        return data;
      } else {
        alert('Failed to fetch the subjects');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch subjects:', error);
      return [];
    }
  };

  useEffect(() => {
    const loadSubjects = async () => {
      const subjectsData = await fetchSubjects();
      setSubjects(subjectsData);
    };
    loadSubjects();
  }, [courseName, year, price]); 

  return (
    <div>
      <h1 class="font-effect-fire" style={{ textAlign: 'center',margin:20, fontFamily: "Sofia" ,  fontWeight: '500', fontSize:'45px'}}>
        Choose Subjects
      </h1>
      <div className="d-flex justify-content-start" style={{ marginLeft: 30, marginTop: 1 }}>
        <Link to="/Courses" className="btn bg-body-tertiary my-4 px-4">
          Go Back
        </Link>
      </div>

      {/* Add Subject Button */}
      {isLoggedIn && accessLevel === 'Admin' && (
        <div className="d-flex justify-content-end" style={{ marginRight: 50 }}>
          <Button variant="success">
            <Link to={`/addSubject/${courseName}/${year}`} style={{ textDecoration: 'none', color: 'white' }}>
              Add Subject
            </Link>
          </Button>
        </div>
      )}

      {/* Subjects List */}
      <div className="container mt-1">
        <div className="row">
          {subjects.length > 0 ? (
            subjects.map((sub, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                <SubjectList sub={sub} />
              </div>
            ))
          ) : (
            <p className="text-center text-secondary">
              No subjects found for the selected course and year.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Subjects;
