import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button, Form, Alert, Table, Card, Row, Col } from 'react-bootstrap';

function Checkout() {
  const { cart, isLoggedIn } = useSelector((state) => ({
    cart: state.cart,
    isLoggedIn: state.isLoggedIn,
  }));
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const paymentUrl = location.state?.paymentUrl;
  const queryParams = new URLSearchParams(location.search);
  const paymentStatus = queryParams.get('status');

  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus === 'success') {
        navigate('/payment-success');
      } else {
        navigate('/courses');
      }
    }
  }, [paymentStatus, navigate]);

  const subtotal = cart.reduce((total, item) => total + item.price, 0);
  const totalPrice = subtotal - discount;

  const handleCouponApply = async () => {
    try {
      const response = await fetch(`https://www.mbascribbles.com:8443/validate?code=${coupon}&price=${subtotal}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Invalid coupon code.');
      }

      const data = await response.json();

      if (data.valid) {
        setDiscount(data.discount);
        setErrorMessage('');
      } else {
        setErrorMessage(data.message || 'Invalid coupon code.');
        setDiscount(0);
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred. Please try again.');
      setDiscount(0);
    }
  };

  const handleRemoveItem = (itemIndex) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { index: itemIndex } });
  };

  const handlePayment = () => {
    const subjectNames = cart.map((item) => item.name); 
    navigate('/pay', { state: { totalPrice, subjectNames } });
  };

  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };

  if (!cart || cart.length === 0) {
    return <div className="text-center my-5">Your cart is empty!</div>;
  }

  return (
    <div className="container my-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <Card className="shadow-lg p-5">
            <Card.Body>
              <h1 className="text-center mb-4">Checkout</h1>
              <Table bordered hover responsive className="mb-4">
                <thead className="bg-light">
                  <tr className='table-primary'>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Description</th>
                    <th>Price (₹)</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>₹{item.price}</td>
                      <td>
                        <Button variant="danger" size="sm" onClick={() => handleRemoveItem(index)}>
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="3" className="text-end font-weight-bold">Subtotal</td>
                    <td>₹{subtotal}</td>
                    <td></td>
                  </tr>
                  {discount > 0 && (
                    <tr>
                      <td colSpan="3" className="text-end font-weight-bold text-success">Discount</td>
                      <td className="text-success">- ₹{discount}</td>
                      <td></td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="3" className="text-end font-weight-bold">Total</td>
                    <td className="font-weight-bold">₹{totalPrice}</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>

              <Row className="mb-3">
                {isLoggedIn ? (
                  <>
                    <Col xs={12} md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Enter coupon code"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value.toUpperCase())}
                      />
                    </Col>
                    <Col xs={12} md={2}>
                      <Button variant="primary" className="w-100" onClick={handleCouponApply}>
                        Apply Coupon
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col xs={12} md={6}>
                    <Button variant="secondary" className="w-100" disabled>
                      Login to apply coupon
                    </Button>
                  </Col>
                )}

              {errorMessage && <Alert variant="danger" className="mt-2">{errorMessage}</Alert>}

                {isLoggedIn ? (
                  <Col className="d-flex justify-content-end">
                    <Button variant="success" onClick={handlePayment}>
                      Make Payment
                    </Button>
                  </Col>
                ) : (
                  <Col className="d-flex justify-content-end">
                    <Alert variant="danger">
                      <a href='/login' className="text-white">Please log in to proceed with payment.</a>
                    </Alert>
                  </Col>
                )}
              </Row>

              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Checkout;
