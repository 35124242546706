import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';

function UploadNotes() {
  const { subjectName, year, course } = useParams();
  const [Semester, setSemester] = useState('');
  const [type, setType] = useState('');
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Capture the selected file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('subject', subjectName);
      formData.append('year', year);
      formData.append('course', course);
      formData.append('semester', Semester);
      formData.append('type', type);
      if (file) {
        formData.append('file', file); // Attach the file to the formData
      }

      const response = await fetch('https://www.mbascribbles.com:8443/uploadNote', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('File uploaded successfully');
        navigate(`/documents/${subjectName}/${year}/${course}`);
      } else {
        alert('Failed to upload the note. Please try again.');
      }
    } catch (error) {
      console.error('Error during upload:', error);
      alert('An error occurred while uploading. Please try again.');
    }
  };

  return (
    <div>
      {/* Go Back Button */}
      <div style={{ textAlign: "left", marginLeft: 20, marginTop: 20 }}>
        <Link to={`/documents/${subjectName}/${year}/${course}`} className="btn bg-body-tertiary my-4 px-4">
          Go Back
        </Link>
      </div>  

      {/* Form Container */}
      <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
        <Container className="shadow-sm p-4 rounded" style={{ maxWidth: '650px', maxHeight:'2000px', backgroundColor:'#f8f9fb' }}>
          <h3 className="text-center mb-4">Upload Notes</h3>
          <Form onSubmit={handleSubmit}>
            {/* Semester Input */}
            <Form.Group className="mb-3" controlId="formGroupSemester">
              <Form.Label>Semester</Form.Label>
              <Form.Control
                type="text"
                placeholder="Specify Semester"
                value={Semester}
                onChange={(e) => setSemester(e.target.value)}
              />
            </Form.Group>

            {/* File Upload */}
            <Form.Group className="mb-3" controlId="formGroupFile">
              <Form.Label>Upload File</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              {file && <small className="text-muted mt-1">Selected file: {file.name}</small>}
            </Form.Group>

            {/* Type Dropdown */}
            <Form.Group className="mb-3" controlId="formGroupType">
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                aria-label="Select the type of note"
              >
                <option value="" disabled>
                  Select the type of note
                </option>
                <option value="HandWrittenNotes">Hand-Written Notes</option>
                <option value="SlmSolutions">SLM Solutions</option>
                <option value="AnswerKeyofPYQP">Answer Key of PYQP</option>
                <option value="ImportanttopicsandPYQPanalysis">Important topics and PYQP analysis</option>
                <option value="QuestionandAnswersforPractice">Question  and Answers for Practice</option>
                <option value="FormulaKey">Formula Key</option>
              </Form.Select>
            </Form.Group>

            {/* Submit Button */}
            <div className="d-flex justify-content-center">
              <Button variant="success" type="submit" className="px-4" >
                Add Note
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </div>
  );
}

export default UploadNotes;
