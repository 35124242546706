import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

function AddCourse() {
  const [name, setName] = useState('');
  const [year, setYear] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!name.trim() || !year.trim() || !description.trim() || !image.trim()) {
      setError('All fields are required. Please fill them in.');
      return;
    }

    setError(''); // Clear error before making the API call
    try {
      const url = new URL('https://www.mbascribbles.com:8443/AddCourse');
      url.searchParams.append('name', name);
      url.searchParams.append('year', year);
      url.searchParams.append('description', description);
      url.searchParams.append('image', image);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        alert(`Course "${name}" added successfully!`);
        navigate('/Courses');
      } else {
        setError('Failed to add the course. Please try again later.');
      }
    } catch (error) {
      console.error('Error during API call:', error);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '90vh' }}
    >
      <Container className="shadow-sm p-4 rounded" style={{  height: '70vh', maxWidth: '40%', backgroundColor:'#f8f9fb' }}>
        <h4 className="text-center mb-4">Add Course</h4>
        <Form onSubmit={handleSubmit}>
          {error && (
            <div className="alert alert-danger text-center" role="alert">
              {error}
            </div>
          )}
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>Course Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the course name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupYear">
            <Form.Label>Year</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the academic year (e.g., 2025)"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Describe the course"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupImage">
            <Form.Label>Image Source</Form.Label>
            <Form.Control
              type="text"
              placeholder="Provide an image URL for the course banner"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3 d-flex justify-content-between">
            <Col sm="auto">
              <Button variant="secondary" onClick={() => navigate('/Courses')}>
                Cancel
              </Button>
            </Col>
            <Col sm="auto">
              <Button type="submit" variant="success">
                Add Course
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
}

export default AddCourse;
