import React, { useEffect, useState } from 'react';
import TableData from '../components/TableData';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useSelector,useDispatch } from 'react-redux';    


function Documents() {
  const { subjectName } = useParams();
  const { year } = useParams();
  const { course } = useParams();
  const [documents, setDocument] = useState([]);
  const { type } = useParams();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const accessLevel = useSelector((state) => state.accessLevel);
  console.log(year)
  console.log(subjectName)
  const fetchDocuments = async () => {
    try {
      const url = new URL('https://www.mbascribbles.com:8443/docuemnts'); 
      url.searchParams.append('subject', subjectName);
      url.searchParams.append('year', year); 
      url.searchParams.append('type', type); 
      console.log(url)
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      }  else if (response.status === 404) {
        alert('No documents found for the selected subject and year.');
        return [];
      } 
    } catch (error) {
      console.error('Failed to fetch docuemnts', error);
      return [];
    }
  };
    useEffect(() => {
      const loadDocument = async () => {
        const documentData = await fetchDocuments();
        console.log(documentData);
        setDocument(documentData);
      };
      loadDocument();
    }, []);
  return (
    <div>    
     <div style={{ textAlign: "left", marginLeft: 20, marginTop: 2 }}>
     <Link to={`/subject/${course}/${year}`} className="btn bg-body-tertiary my-4 px-3" >
    Go Back
  </Link>
  </div>      
      <div style={{ textAlign: "right",marginRight:40,marginTop:40 }}>
      {isLoggedIn && accessLevel === 'Admin' &&(
         <Button variant="success">
         <Link to={`/uploadNotes/${subjectName}/${year}/${course}`} style={{ textDecoration: 'none', color: 'white' }}>
      Upload Notes
      </Link>
</Button>)}</div>
    <div className="container my-4">

      <div className="row d-flex justify-content-center">
        {documents.map((document, index) => (
          <div key={index} className="d-flex align-items-stretch">
            <div className="card w-100">
              <TableData document={document} index={index} />
            </div>
          </div>
        ))}
      </div>
    </div></div>
  )

}

export default Documents