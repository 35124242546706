import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { totalPrice, subjectNames } = location.state || {};
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { email, phoneNo, name } = useSelector((state) => ({
    email: state.email,
    phoneNo: state.phoneNo,
    name: state.name,
  }));

  const generateOrderId = () => {
    const timestamp = Date.now();
    const randomPart = Math.floor(1000 + Math.random() * 9000);
    return `ORD-${timestamp}-${randomPart}`;
  };

  const makePaymentRequest = async () => {
    setLoading(true);
    setError(null);

    try {
      const order_id = generateOrderId();
      dispatch({
        type: 'ORDER_ID',
        payload: { OrderId: order_id },
      });

      const response = await fetch('https://www.mbascribbles.com:8443/payment', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: "ba6077-f00a1c-836d77-18de63-9c9e81",
          order_id: order_id,
          txn_amount: totalPrice,
          txn_note: "Pay For Scribbles",
          product_name: Array.isArray(subjectNames) ? subjectNames.join(", ") : JSON.stringify(subjectNames),
          customer_name: name,
          customer_mobile: phoneNo,
          customer_email: email,
          redirect_url: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Payment creation failed!");
      }

      const data = await response.json();
      setPaymentData(data);

      if (data.results && data.results.length > 0) {
        const paymentUrl = data.results[0].payment_url;

        if (paymentUrl) {
          window.open(paymentUrl, "_blank", "width=600,height=600");
          pollPaymentStatus(order_id); // Start polling for payment status
        } else {
          setError("Payment URL not available.");
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const pollPaymentStatus = async (order_id) => {
    const maxAttempts = 12; // Poll for 1 minute (12 attempts with a 5-second interval)
    let attempts = 0;

    const interval = setInterval(async () => {
      attempts++;
      try {
        const response = await fetch(`https://www.mbascribbles.com:8443/status?orderId=${order_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        if (data.results && data.results.results.status === "Success") {
          const productNames = data.results.results.product_name.split(', ');
          await updateUserInfo(productNames); // Update user info with purchased products
          clearInterval(interval); // Stop polling
          navigate("/Courses"); // Redirect on success
        } else if (attempts >= maxAttempts) {
          setError("Payment verification timed out.");
          clearInterval(interval); // Stop polling after max attempts
        }
      } catch (err) {
        console.error("Error while polling payment status:", err.message);
        clearInterval(interval);
        setError("Error checking payment status.");
      }
    }, 5000);
  };

  const updateUserInfo = async (productNames) => {
    try {
      const response = await fetch('https://www.mbascribbles.com:8443/updateUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          subjects: Array.isArray(productNames) ? productNames : [productNames],
        }),
      });

      if (!response.ok) {
        throw new Error(`Error updating user info: ${response.status}`);
      }

      const data = await response.json();
      console.log('User info updated successfully:', data);
    } catch (error) {
      console.error('Failed to update user info:', error.message);
    }
  };

  useEffect(() => {
    if (totalPrice && subjectNames) {
      makePaymentRequest();
    }
  }, [totalPrice, subjectNames]);

  return (
    <div>
      <h2>Payment Page</h2>
      {loading && <p>Loading... Please wait.</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {paymentData && paymentData.results && paymentData.results.length > 0 && (
        <div>
          <h3>Payment Info:</h3>
          <p>Transaction ID: {paymentData.results[0].txn_id}</p>
          <p>Product: {paymentData.results[0].product_name}</p>
          <p>Payment Mode: {paymentData.results[0].payment_mode}</p>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
