import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Container, Alert } from 'react-bootstrap';
import './UserAccessControl.css';
import { useSelector} from "react-redux";

function UserAccessControl() {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState('');
  const [isEmailValidated, setIsEmailValidated] = useState(false);  // Added state to track email validation
  const [isSubjectsFetched, setIsSubjectsFetched] = useState(false);  // Added state to track subject fetch

  const { isLoggedIn, accessLevel, name } = useSelector((state) => ({
    isLoggedIn: state.isLoggedIn,
    accessLevel: state.accessLevel,
    name: state.name || '',
  }));

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('https://www.mbascribbles.com:8443/courses', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setCourses(data);
        } else {
          setError('Failed to fetch courses.');
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
        setError('An error occurred while fetching courses.');
      }
    };

    fetchCourses();
  }, []);

  // Handle Email Validation
  const handleValidateEmail = async (e) => {
    e.preventDefault();
    setError('');
    setUpdateSuccess('');
    if (!email) {
      setError('Please enter an email address.');
      return;
    }
    setLoading(true);
    try {
      const url = new URL('https://www.mbascribbles.com:8443/validateEmail');
      url.searchParams.append('email', email);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data === true) {
          setIsValid(true);
          setIsEmailValidated(true); // Mark email as validated
        } else {
          setIsValid(false);
          setError('Invalid email.');
        }
      } else {
        setIsValid(false);
        setError('Failed to validate email.');
      }
    } catch (error) {
      console.error('Error validating email:', error);
      setError('An error occurred while validating the email.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch Subjects based on selected course and year
  const fetchSubjects = async () => {
    setLoading(true);
    setError('');
    try {
      const url = new URL('https://www.mbascribbles.com:8443/getSubject');
      url.searchParams.append('course', selectedCourse);
      url.searchParams.append('year', selectedYear);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSubjects(data);
        setIsSubjectsFetched(true);  // Mark subjects as fetched
      } else {
        setError('Failed to fetch subjects.');
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
      setError('An error occurred while fetching subjects.');
    } finally {
      setLoading(false);
    }
  };

  // Handle Checkbox Change
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSubjects((prev) => [...prev, value]);
    } else {
      setSelectedSubjects((prev) => prev.filter((subject) => subject !== value));
    }
  };

  // Handle Update User Info
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setError('');
    setUpdateSuccess('');
    if (selectedSubjects.length === 0) {
      setError('Please select at least one subject.');
      return;
    }
    setLoading(true);
    try {
      const url = new URL('https://www.mbascribbles.com:8443/updateUser');
      const payload = {
        email: email,
        subjects: selectedSubjects,
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        const message = await response.text();
        setUpdateSuccess(message);
      } else {
        const errorMessage = await response.text();
        setError(errorMessage || 'Failed to update user information.');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setError('An error occurred while updating user information.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">User Access Control</h1>

      {error && <Alert variant="danger">{error}</Alert>}
      {updateSuccess && <Alert variant="success">{updateSuccess}</Alert>}

      {/* Email validation form */}
      <Form onSubmit={isValid ? handleUpdateUser : handleValidateEmail}>
        <Form.Group className="mb-3" controlId="formGroupEmail" style={{ maxWidth: '400px', margin: '0 auto' }}>
          <Form.Label>Enter User Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Button
          variant="info"
          type="submit"
          className="mt-3"
          disabled={loading}
        >
          {isValid ? 'Update User' : 'Find User'}
        </Button>
      </Form>

      {loading && <p className="text-center">Loading...</p>}

      {/* Show Course Dropdown and Subject Fetching after email validation */}
      {isEmailValidated && courses.length > 0 && (
        <Form.Group className="mb-3" controlId="formGroupCourses" style={{ maxWidth: '400px', margin: '0 auto' }}>
          <Form.Label>Select Course</Form.Label>
          <Form.Select
            onChange={(e) => {
              const selected = courses.find((course) => course.name === e.target.value);
              setSelectedCourse(selected.name);
              setSelectedYear(selected.year);
            }}
            required
          >
            <option value="">Select a course</option>
            {courses.map((course) => (
              <option key={course.name} value={course.name}>
                {course.name} ({course.year})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}

      {/* Show "Fetch Subjects" button after course selection */}
      {isEmailValidated && selectedCourse && selectedYear && !isSubjectsFetched && (
        <Button
          variant="primary"
          onClick={fetchSubjects}
          className="mt-3"
          disabled={loading}
        >
          Fetch Subjects
        </Button>
      )}

      {/* Show subject selection and "Update User" button after subjects are fetched */}
      {isSubjectsFetched && subjects.length > 0 && (
        <Form onSubmit={handleUpdateUser}>
          <Form.Group className="mb-3" controlId="formSubjects">
            <Form.Label>Select Subjects</Form.Label>
            <div>
              {subjects.map((subject) => (
                <Form.Check
                  type="checkbox"
                  key={subject.subject}
                  id={`subject-${subject.subject}`}
                  label={subject.subject}
                  value={subject.subject}
                  onChange={handleCheckboxChange}
                />
              ))}
            </div>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={loading || selectedSubjects.length === 0}
          >
            Update User Subjects
          </Button>
        </Form>
      )}
    </Container>
  );
}

export default UserAccessControl;
