// PaymentSuccess.js
import React from "react";
import { Link } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <div>
      <h2>Payment Successful!</h2>
      <p>Thank you for your payment. You can now access your courses.</p>
      <Link to="/courses">Go to Courses</Link>
    </div>
  );
};

export default PaymentSuccess;
