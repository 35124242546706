import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Navbar.css';

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const contentHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      if (scrollPosition + viewportHeight >= contentHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        marginBottom: 0,
        display: isVisible ? 'block' : 'none',
        zIndex: 999,
      }}
    >
      <footer
        className="bg-body-tertiary shadow-sm py-3"
        style={{
          borderTop: '2px solid #d1d5db',
          fontSize: '0.9rem',
        }}
      >
        <Container>
          <Row className="text-center">
              <span>&copy; {new Date().getFullYear()}
              <strong style={{paddingRight: 10, paddingLeft: 10}}>Mba Scribbles</strong> |

                <i className="fas fa-phone me-2" style={{paddingLeft: 10}}></i>
                Contact Us (Ritika) :<a href="tel:7701921566" style={{paddingRight: 10}}> 7701921566 </a>|

                <i className="fas fa-tools me-2" style={{paddingLeft: 10}}></i>
                Tech Support : 
                <a href="tel:9560699172" className="ms-1">9560699172 </a>, 
                <a href="tel:7011974451" className="ms-1">7011974451</a>
              </span>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
