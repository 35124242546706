import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../screens/actions';

function SubjectList({ sub }) {
  const { isLoggedIn, accessLevel, SubjectList } = useSelector((state) => ({
    isLoggedIn: state.isLoggedIn,
    accessLevel: state.accessLevel,
    SubjectList: state.SubjectList,
  }));

  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const item = {
      name: sub.subject,
      price: 50,
      description: sub.course,
    };
    console.log('Adding to cart:', item);
    alert(`${sub.subject} added to cart`);
    dispatch(addToCart(item));
  };

  // Check if the subject is already bought or saved in the SubjectList
  const isAlreadyBought = (SubjectList || []).some(
    (savedSub) => savedSub.subject === sub.subject
  );

  return (
    <Container className="text-center">
      <div style={{ margin: '50px auto' }}>
        <Card
          style={{
            width: '18rem',
            height: '13rem',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease',
          }}
          className="hover-shadow"
        >
          <Card.Body>
            <Row>
              <Card.Title
                className="text-center"
                style={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#333' }}
              >
                {sub.subject}
              </Card.Title>
              <Col>
                <Card.Subtitle className="mb-2 text-muted">{sub.course}</Card.Subtitle>
                <Card.Text className="text-muted">{sub.year}</Card.Text>
              </Col>

              {isLoggedIn && (['Admin', 'Superuser'].includes(accessLevel) || isAlreadyBought) ? (
                <Row className="d-flex justify-content-center">
                  <Link
                    to={`/documents/${sub.subject}/${sub.year}/${sub.course}`}
                    className="btn btn-success my-3 px-4 rounded-pill"
                    style={{ fontWeight: 'bold', letterSpacing: '1px' }}
                  >
                    CHECK SUBJECT NOTES
                  </Link>
                </Row>
              ) : (
                <Col style={{ textAlign: 'right' }}>
                  <Card.Text style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>₹ 50</Card.Text>
                  <button
                    className="btn my-3 px-4 rounded-pill d-block mx-auto"
                    style={{
                      backgroundColor: '#FFD700',
                      color: 'black',
                      fontWeight: 'bold',
                      letterSpacing: '1px',
                      transition: 'background-color 0.3s ease',
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = '#FFB400')}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = '#FFD700')}
                    onClick={handleAddToCart}
                  >
                    <i className="fas fa-shopping-cart"></i> CART
                  </button>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}

export default SubjectList;
