  import React, { useState, useEffect, useRef } from 'react';
  import * as pdfjsLib from 'pdfjs-dist/webpack';
  import { useSelector } from 'react-redux';

  // Set the worker source
  pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';

  function TableData({ document, index }) {
    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    const accessLevel = useSelector((state) => state.accessLevel);
    const subjectList = useSelector((state) => state.SubjectList);
    const email = useSelector((state) => state.email);
    const [viewUrl, setViewUrl] = useState(null);
    const [pdfDocument, setPdfDocument] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isPdfCollapsed, setIsPdfCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Initial loading state
    const [isViewing, setIsViewing] = useState(false); // To disable the view button
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false); // Track document loading
    const canvasRef = useRef(null);

    useEffect(() => {
      // Simulate data loading
      setTimeout(() => {
        setIsLoading(false);
      }, 1000); // Simulated loading time
    }, []);

    const handleView = async (fileName) => {
      if (isPdfCollapsed) {
        setIsPdfCollapsed(false); // Expand the view if collapsed
      }
      setIsViewing(true); // Disable "View File" button when clicked
      setIsDocumentLoaded(false);

      try {
        const response = await fetch(`https://www.mbascribbles.com:8443/download/${fileName}`, {
          method: 'GET',
          headers: { 'Accept': 'application/pdf' },
          mode: 'cors',
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          setViewUrl(url);
        } else {
          alert('Failed to load the document for viewing');
          setIsViewing(false);
        }
      } catch (error) {
        console.error('Error viewing document:', error);
        setIsViewing(false);
      }
    };

    const deleteFile = async (fileName) => {
      try {
        const response = await fetch(`https://www.mbascribbles.com:8443/delete/${fileName}`, {
          method: 'GET',
          mode: 'cors',
        });

        if (response.ok) {
          const message = await response.text();
          alert(message);
          window.location.reload();
        } else {
          alert('Failed to delete file');
        }
      } catch (error) {
        console.error('Error deleting document:', error);
        alert('An unexpected error occurred.');
      }
    };

    const renderPdf = (pageNum) => {
      if (pdfDocument && canvasRef.current) {
        pdfDocument.getPage(pageNum).then((page) => {
          const viewport = page.getViewport({ scale: 4.0 });
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');
    
          canvas.height = viewport.height;
          canvas.width = viewport.width;
    
          // Cancel any ongoing render task
          if (canvas.renderTask && !canvas.renderTask.cancelled) {
            canvas.renderTask.cancel();
          }
    
          // Start a new render task
          const renderTask = page.render({
            canvasContext: context,
            viewport: viewport,
          });
    
          canvas.renderTask = renderTask;
    
          renderTask.promise
            .then(() => {
              context.save();
              context.font = '50px Arial';
              context.fillStyle = 'rgba(255, 0, 0, 0.5)';
              const text = email;
              const textMetrics = context.measureText(text);
              const textWidth = textMetrics.width;
              const textHeight = textMetrics.actualBoundingBoxAscent;
    
              const centerX = canvas.width / 2;
              const centerY = canvas.height / 2;
    
              context.translate(centerX, centerY);
              context.rotate(-Math.PI / 6);
              context.fillText(text, -textWidth / 2, textHeight / 2);
              context.restore();
    
              setIsDocumentLoaded(true);
            })
            .catch((error) => {
              if (error.name === 'RenderingCancelledException') {
                console.log('Rendering was cancelled.');
              } else {
                console.error('Error rendering PDF:', error);
              }
            });
        });
      }
    };
    

    useEffect(() => {
      if (isPdfCollapsed && canvasRef.current) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
      }
    }, [isPdfCollapsed]);

    useEffect(() => {
      if (viewUrl && !isPdfCollapsed) {
        const loadingTask = pdfjsLib.getDocument(viewUrl);
        loadingTask.promise
          .then((pdf) => {
            setPdfDocument(pdf);
            setTotalPages(pdf.numPages);
            setCurrentPage(1);
            renderPdf(1);
          })
          .catch((error) => {
            console.error('Error loading PDF document:', error);
          });
      }
    }, [viewUrl, isPdfCollapsed]);

    useEffect(() => {
      if (pdfDocument) {
        renderPdf(currentPage);
      }
    }, [currentPage, pdfDocument]);

    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prev) => prev + 1);
      }
    };

    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
      }
    };

    const handleCollapse = () => {
      setIsPdfCollapsed(!isPdfCollapsed);
      if (!isPdfCollapsed && viewUrl) {
        const loadingTask = pdfjsLib.getDocument(viewUrl);
        loadingTask.promise
          .then((pdf) => {
            setPdfDocument(pdf);
            setTotalPages(pdf.numPages);
            setCurrentPage(1);
            renderPdf(1);
          })
          .catch((error) => {
            console.error('Error loading PDF document after expanding:', error);
          });
      }
    };

    const isAllowedToView = () => {
      if (accessLevel === 'Admin') {
        return true;
      }

      if (subjectList) {
        const subjectNames = subjectList.map((sub) => sub.subject);
        if (subjectNames.includes(document.subject)) {
          return true;
        }
      }

      return false;
    };

    if (isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <div style={{ paddingBottom: 50 }}>
        <table className="table table-hover table-bordered my-2" style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr className='table-primary'>
              <th style={{ width: '25%', textAlign: 'center' }}>Name</th>
              <th style={{ width: '25%', textAlign: 'center' }}>Subject</th>
              <th style={{ width: '25%', textAlign: 'center' }}>Year</th>
              <th style={{ width: '25%', textAlign: 'center' }}>Semester</th>
              <th style={{ width: '25%', textAlign: 'center' }}>Action</th>
              <th style={{ width: '25%', textAlign: 'center' }}>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr key={index}>
              <td style={{ width: '25%', height: '50px', textAlign: 'center' }}>{document.name}</td>
              <td style={{ width: '25%', height: '50px', textAlign: 'center' }}>{document.subject || 'N/A'}</td>
              <td style={{ width: '25%', height: '50px', textAlign: 'center' }}>{document.year}</td>
              <td style={{ width: '25%', height: '50px', textAlign: 'center' }}>{document.semester}</td>
              <td style={{ width: '25%', height: '50px', textAlign: 'center' }}>
                {isAllowedToView() ? (
                  <button 
                    className="btn btn-success" 
                    onClick={() => handleView(document.name)}
                    disabled={isViewing}>
                    {isViewing ? (isDocumentLoaded ? 'Loaded' : 'Loading... Please Wait') : 'View File'}
                  </button>
                ) : (
                  alert('Permission denied')
                )}
              </td>
              {isLoggedIn && accessLevel === 'Admin' && (
                <td style={{ width: '25%', height: '50px', textAlign: 'center' }}>
                  <button className="btn btn-danger" onClick={() => deleteFile(document.name)}>
                    Delete File
                  </button>
                </td>
              )}
            </tr>
          </tbody>
        </table>

        {viewUrl && !isPdfCollapsed && (
          <div className="pdf-viewer mt-3">
            <canvas ref={canvasRef} style={{ width: '100%' }} />
            <div className="d-flex justify-content-between mt-2">
              <button
                className="btn btn-primary"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="btn btn-primary"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <div style={{ paddingRight:50}}>
              <button
                className="btn btn-warning"

                  onClick={handleCollapse}
                >
                  Collapse View
                </button>
                </div>
              </div>
            </div>
          )}

          {isPdfCollapsed && (
            <div className="mt-3"  style={{ paddingRight:50}}>
              <button className="btn btn-info" onClick={handleCollapse}  style={{ float: "right"}}>
                View Document
              </button>
            </div>
          )}
        </div>
      );
    }

    export default TableData;
