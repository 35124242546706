import React from 'react';
import { Nav, Container, Navbar, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import './Navbar.css';
// import LogoutButton from './LogoutButton';
// import { useNavigate } from 'react-router-dom';
const NavScrollExample = () => {
  const { isLoggedIn, accessLevel, name ,email} = useSelector((state) => ({
    isLoggedIn: state.isLoggedIn,
    accessLevel: state.accessLevel,
    name: state.name || '',
    email : state.email,
  }));  
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      const response = await fetch('https://www.mbascribbles.com:8443/out?email=' + encodeURIComponent(email), {
        method: 'POST',
      });

      if (response.ok) {
        dispatch({ type: 'LOGOUT' });
      } else {
        console.error('Failed to logout. Server responded with:', response.status);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const shortenedName = name.length > 4 ? `${name.slice(0, 4)}..` : name;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  );

  return (
    <Navbar expand="lg" className="bg-body-tertiary shadow-sm">
      <Container fluid>
        <Navbar.Brand href="/" className="Nav" style={{paddingLeft:10}}>
        <i className="fas fa-book-reader me-2"></i> MbaScribbles
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          {/* Left-side Navigation */}
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
          <Nav.Link href="/Courses">
            <i className="fas fa-th-list me-2"></i>COURSES
            </Nav.Link>
            <Nav.Link
          href="https://sol.du.ac.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-graduation-cap me-2"></i> SOL
            </Nav.Link>
          </Nav>
          {/* Right-side Navigation */}
          <Nav className="ms-auto">
          <Nav.Link href="/checkOut">
          <i className="fas fa-shopping-cart me-2"></i> CART
            </Nav.Link>
            {isLoggedIn ? (
              <>
                <Nav.Link href="/myCourses" className="Nav">
                <i className="fas fa-book me-2"></i>My Learnings
                  </Nav.Link>
                <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                  <Nav.Link className="Nav">
                    WELCOME, {shortenedName}
                  </Nav.Link>
                </OverlayTrigger>
                <Nav.Link className="Nav" onClick={handleLogout} style={{paddingRight:15}}>
                  <i className="fas fa-sign-out-alt me-2"></i> LOGOUT
                </Nav.Link>
                {accessLevel === 'Admin' && (
                  <Nav.Link href="/granAccessControl" className="Nav">
                    <i className="fas fa-user-cog me-2"></i> Grant Access
                  </Nav.Link>
                )}
              </>
            ) : (
              <>
                <Nav.Link href="/login">
                <i className="fas fa-user me-2"></i> LOGIN
                </Nav.Link>
                <Nav.Link href="/signup" style={{paddingRight:15}}>
          <i className="fas fa-user-plus me-2"></i> SIGN UP
                </Nav.Link>
              </>
            )}
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavScrollExample;
