import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const [Name, setName] = useState('');
  const [Country, setCounty] = useState('');
  const [ContactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [createPassword, setcreatePassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!Name.trim()) newErrors.Name = 'Name is required';
    if (!Country.trim()) newErrors.Country = 'Country is required';
    if (!ContactNo.trim()) newErrors.ContactNo = 'Contact number is required';
    if (!email.trim()) newErrors.email = 'Email is required';
    if (!createPassword.trim()) newErrors.createPassword = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // If validation fails, stop submission

    try {
      const url = new URL('https://www.mbascribbles.com:8443/users/add');
      url.searchParams.append('name', Name);
      url.searchParams.append('country', Country);
      url.searchParams.append('phoneNo', ContactNo);
      url.searchParams.append('email', email);
      url.searchParams.append('password', createPassword);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('User Added Successfully');
        navigate('/login');
      } else {
        alert('SignUp Failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error during signup:', error);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '80vh' }}
    >
      <Container className="mx-auto" style={{ maxWidth: '35%' }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={!!errors.Name}
            />
            <Form.Control.Feedback type="invalid">{errors.Name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              value={Country}
              onChange={(e) => setCounty(e.target.value)}
              isInvalid={!!errors.Country}
            />
            <Form.Control.Feedback type="invalid">{errors.Country}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicContactNo">
            <Form.Label>Contact No</Form.Label>
            <Form.Control
              type="text"
              placeholder="+91 ..."
              value={ContactNo}
              onChange={(e) => setContactNo(e.target.value)}
              isInvalid={!!errors.ContactNo}
            />
            <Form.Control.Feedback type="invalid">{errors.ContactNo}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Create Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={createPassword}
              onChange={(e) => setcreatePassword(e.target.value)}
              isInvalid={!!errors.createPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.createPassword}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Accept terms and conditions" />
          </Form.Group>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default SignUp;
