import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function BasicExample({course}) {
  return (
    <Container className="text-center">
        <div style={{ margin: '50px auto' }}>
    <Card   style={{ 
    width: '18rem', 
    height: '27rem', // Set a fixed height
    borderRadius: '10px', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
    transition: 'transform 0.3s ease' 
  }} >
      <Card.Img variant="top" src={course.imgSrc}/>
      <Card.Body>
        <Card.Title>{course.name}</Card.Title>
        <Card.Text>
          {course.description}
        </Card.Text>
        <Card.Text>
          {course.year}
        </Card.Text>
        <Link to={`/subject/${course.name}/${course.year}`} className="btn btn-success my-4 px-3">
  Check All the Subjects
</Link>
      </Card.Body>
    </Card>
    </div>
    </Container>
  );
}

export default BasicExample;