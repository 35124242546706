import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import to connect to Redux store
import './SelectNotes.css';
import { Container } from 'react-bootstrap';

const categories = [
  { name: 'Handwritten', type: 'HandWrittenNotes' },
  { name: 'SLM Solutions', type: 'SlmSolutions' },
  { name: 'Answer Key of PYQP', type: 'AnswerKeyofPYQP' },
  { name: 'Important topics and PYQP analysis', type: 'ImportanttopicsandPYQPanalysis' },
  { name: 'Question  and Answers for Practice', type: 'QuestionandAnswersforPractice' },
  { name: 'Formula Key', type: 'FormulaKey' },
];

function SelectNotes() {
  const { subjectName, year, course } = useParams();
  const [documents, setDocuments] = useState([]);
  const [availableNotes, setAvailableNotes] = useState([]);
  
  // Access Redux state to check the access level
  const accessLevel = useSelector((state) => state.accessLevel);

  const fetchDocuments = async () => {
    try {
      const url = new URL('https://www.mbascribbles.com:8443/documents');
      url.searchParams.append('subjectName', subjectName);
      url.searchParams.append('year', year);
      url.searchParams.append('course', course);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDocuments(data);
      } else {
        console.error('Failed to fetch documents');
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const fetchAvailableNotes = async () => {
    try {
      const url = new URL('https://www.mbascribbles.com:8443/fetchAvailableNotes');
      url.searchParams.append('subject', subjectName);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setAvailableNotes(data);
        console.log(data);
      } else {
        console.error('Failed to fetch available notes');
      }
    } catch (error) {
      console.error('Error fetching available notes:', error);
    }
  };
  console.log(accessLevel)
  useEffect(() => {
    fetchDocuments();
    fetchAvailableNotes();
  }, [subjectName, year, course]);

  return (
    <div>
      {/* Heading */}
      <h1
        className="font-effect-fire"
        style={{
          textAlign: 'center',
          margin: 20,
          fontFamily: 'Sofia',
          fontWeight: '500',
          fontSize: '45px',
        }}
      >
        {subjectName}
      </h1>
      
      {/* Go Back Button */}
      <div style={{ textAlign: 'left', marginLeft: 20, marginTop: 1 }}>
        <Link to={`/subject/${course}/${year}`} className="btn bg-body-tertiary my-4 px-3">
          Go Back
        </Link>
      </div>

      {/* Cards Row */}
      <Container className="text-center">
        <div className="Container">
          <Row className="my-1 p-5 rounded">
            {categories.map((category) => (
              <Col key={category.type}>
                <Card
                  className="my-2 shadow-lg border-0 rounded-3 p-4"
                  style={{
                    height: 150,
                    width: 300,
                    backgroundColor: availableNotes.includes(category.type)
                      ? '#a4d46c'
                      : '#d3d3d3', // Green if available, gray if not
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  }}
                >
                  <Card.Body>
                    <Link
                      to={
                        availableNotes.includes(category.type) || accessLevel === 'Admin'
                          ? `/docType/${subjectName}/${year}/${course}/${category.type}`
                          : '#'
                      }
                      style={{
                        textDecoration: 'none',
                        pointerEvents:
                          availableNotes.includes(category.type) || accessLevel === 'Admin'
                            ? 'auto'
                            : 'none',
                      }}
                    >
                      <Card.Title
                        as="div"
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '1.50rem',
                          color: 'white',
                        }}
                      >
                        {category.name}
                      </Card.Title>
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default SelectNotes;
