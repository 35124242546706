import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SubjectList from '../components/SubjectList'; // Import SubjectList for reuse.

function Search() {
  const { subjectName } = useParams(); // Gets `subjectName` from the URL.
  const [subjectData, setSubjectData] = useState(null); // State to store the fetched subject data.
  const [error, setError] = useState(null); // State to handle errors.

  // Fetch subject data based on the search query.
  const fetchSubjectData = async () => {
    try {
      const url = new URL('https://www.mbascribbles.com:8443/subject');
      url.searchParams.append('subject', subjectName);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setSubjectData(data); // Update the state with fetched data.
    } catch (err) {
      setError(err.message); // Update error state in case of failure.
    }
  };

  // Fetch data on component mount or when `subjectName` changes.
  useEffect(() => {
    fetchSubjectData();
  }, [subjectName]);

  return (
    <Container>
      <div style={{ margin: 60 }}>
        {error && <p className="text-danger">{error}</p>} {/* Display error if any */}
        {subjectData ? (
          <SubjectList sub={subjectData} /> // Pass the fetched subject data to SubjectList
        ) : (
          <p>Loading subject details...</p> // Display loading indicator while fetching data.
        )}
      </div>
    </Container>
  );
}

export default Search;
