// src/components/ExampleCarouselImage.js
import React from 'react';

function ExampleCarouselImage({ text }) {
  console.log(text);
  const imageSrc = `/images/${text.replace(/\s+/g, '_').toLowerCase()}.jpg`;

  return (
    <img
      src={imageSrc}
      alt={text}
      style={{ width: '100%', height: 'auto', maxHeight: '625px', objectFit: 'cover' }}
    />
  );
}

export default ExampleCarouselImage;
