import React, { useState } from "react";
import ExampleCarouselImage from "../components/ExampleCarouselImage";
import { Container, Form, ListGroup, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaSearch } from 'react-icons/fa'; // Import the search icon

function Main() {
  const [searchTerm, setSearchTerm] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const navigate = useNavigate();  // Initialize the navigate function
  
  const handleRecommendationClick = (recommendation) => {
    console.log("Recommendation clicked:", recommendation);
    navigate(`/subject/${recommendation}`);
  };
  const handleSearch = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await fetch(
          `https://www.mbascribbles.com:8443/getspecific?subject=${encodeURIComponent(query)}`
        );

        if (response.ok) {
          const data = await response.json();
          setRecommendations(data); // Set the recommended subjects
        } else {
          console.error("Failed to fetch recommendations:", response.statusText);
          setRecommendations([]);
        }
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        setRecommendations([]);
      }
    } else {
      setRecommendations([]); // Clear recommendations if input is empty
    }
  };

  return (
    <div>
      <Container>
        <ExampleCarouselImage text="logoo" />
        <div
          style={{display: "flex",flexDirection: "column",alignItems: "center", justifyContent: "center",
          }}
        >
          <Form style={{ marginTop: "20px", width: "50%" }}>
          <InputGroup>
            <InputGroup.Text style={{ backgroundColor: 'transparent', border: 'none' }}>
              <FaSearch style={{ fontSize: '18px', color: 'green' }} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              style={{padding: "10px", fontSize: "16px", borderRadius: "25px",boxShadow: "0px 2px 5px rgba(0, 100, 0, 0.5)",
              }}
            />
          </InputGroup>
          </Form>
          {recommendations.length > 0 && (
            <ListGroup style={{ marginTop: "10px", width: "50%" }}> 
              {recommendations.map((recommendation, index) => (
                <ListGroup.Item
                  key={index}
                  style={{
                    backgroundColor: "#cadee3",
                    color: "Black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRecommendationClick(recommendation)}
                >
                  {recommendation}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Main;
