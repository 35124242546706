import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom';

function AddSubject() {
  const { courseParam } = useParams(); // Fetch course from the URL
  const [subjectName, setName] = useState('');
  const [year, setYear] = useState('');
  const [course, setCourse] = useState(courseParam || ''); // Default to the URL value or an empty string
  const [error, setError] = useState(''); // Error state for validation or server issues

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Input validation
    if (!subjectName.trim() || !year.trim()) {
      setError('All fields are required. Please fill them in.');
      return;
    }

    setError(''); // Clear error before submission
    try {
      const url = new URL('https://www.mbascribbles.com:8443/addSubject');
      url.searchParams.append('subjectName', subjectName);
      url.searchParams.append('year', year);
      url.searchParams.append('course', course);

      const response = await fetch(url, {
        method: 'GET', // Use GET if you're sending data via query parameters
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        alert(`Subject "${subjectName}" added successfully!`);
        navigate(`/subject/${course}/${year}`);
      } else {
        setError('Failed to add the subject. Please try again later.');
      }
    } catch (error) {
      console.error('Error adding subject:', error);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '80vh' }}
    >
      <Container className="shadow-sm p-4 rounded" style={{ maxWidth: '35%', backgroundColor:'#f8f9fb' }}>
        <h4 className="text-center mb-4">Add Subject</h4>
        <Form onSubmit={handleSubmit}>
          {error && (
            <div className="alert alert-danger text-center" role="alert">
              {error}
            </div>
          )}
          <Form.Group className="mb-3" controlId="formGroupSubjectName">
            <Form.Label>Subject Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the subject name"
              value={subjectName}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupYear">
            <Form.Label>Year</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the academic year (e.g., 2025)"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupCourse">
            <Form.Label>Course</Form.Label>
            <Form.Control
              type="text"
              value={course}
              readOnly
              title="This field is pre-filled based on the course."
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3 d-flex justify-content-between">
            <Col sm="auto">
              <Button variant="secondary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Col>
            <Col sm="auto">
              <Button type="submit" variant="success">
                Add Subject
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
}

export default AddSubject;
