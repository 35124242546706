import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchSubjects = async (userEmail) => {
    try {
      const url = new URL('https://www.mbascribbles.com:8443/myLearnings');
      url.searchParams.append('email', userEmail);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: 'SAVE_SUBJECTS',
          payload: { subjects: data },
        });
      } else {
        console.error('Failed to fetch subjects.');
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlertMessage('');

    try {
      const url = new URL('https://www.mbascribbles.com:8443/login');
      url.searchParams.append('email', email);
      url.searchParams.append('password', password);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        dispatch({
          type: 'LOGIN',
          payload: {
            accessLevel: data.accessLevel,
            name: data.name,
            email: data.email,
            phoneNo: data.phoneNo,
            sessionId : data.sessionId,
          },
        });

        // Fetch and store subjects after login
        await fetchSubjects(data.email);

        navigate('/Courses', { state: { accessLevel: data.accessLevel } });
      } else if (response.status === 409) {
        const errorData = await response.json();
        setAlertMessage(errorData.message || 'Account conflict. Please try again.');
      } else if (response.status === 401) {
        setAlertMessage('Invalid email or password. Please try again.');
      } else {
        setAlertMessage('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setAlertMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '80vh' }}
    >
      <Container className="mx-auto" style={{ maxWidth: '35%' }}>
        <Form onSubmit={handleSubmit}>
          {alertMessage && (
            <div className="alert alert-danger text-center" role="alert">
              {alertMessage}
            </div>
          )}
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email or username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3 d-flex justify-content-end">
            <Col sm="auto">
              <Button type="submit" variant="success">
                Sign in
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
}

export default LoginScreen;
