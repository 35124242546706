import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useSessionTimeout(interval = 6000) {
  const dispatch = useDispatch();
  // const { isLoggedIn, email } = useSelector((state) => ({
  //   isLoggedIn: state.isLoggedIn,
  //   email: state.email,
  // }));
  const { sessionId, email } = useSelector((state) => ({
    sessionId: state.sessionId,
    email: state.email,
  }));
  // console.log(email,sessionId)
  useEffect(() => {
    const validateSession = async () => {
      if (!email || !sessionId) {
        return;
      }
      try {
        const url = new URL('https://www.mbascribbles.com:8443/validate-session');
        url.searchParams.append('email', email);
        url.searchParams.append('sessionId', sessionId);

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          alert('Your session has expired. Please log in again.');
          dispatch({ type: 'LOGOUT' });
          localStorage.clear();
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Error during session validation:', error);
      }
    };

    const intervalId = setInterval(validateSession, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, interval]);

  return null;
}
export default useSessionTimeout;
