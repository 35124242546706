import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import SubjectList from '../components/SubjectList';
import { useSelector, useDispatch } from 'react-redux';

function MyLearnings() {
  const dispatch = useDispatch(); // Add useDispatch
  const myLearnings = useSelector((state) => state.SubjectList || []); 
  const [subjects, setSubjects] = useState([]);
  const email = useSelector((state) => state.email);

  const fetchSubjects = async () => {
    try {
      const url = new URL('https://www.mbascribbles.com:8443/myLearnings');
      url.searchParams.append('email', email);

      console.log('Fetching URL:', url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched Subjects:', data);
        
        // Dispatch action to save subjects in store
        dispatch({
          type: 'SAVE_SUBJECTS',
          payload: { subjects: data },
        });

        return data;
      } else {
        alert('Failed to fetch the subjects');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch subjects:', error);
      return [];
    }
  };

  useEffect(() => {
    const loadSubjects = async () => {
      const subjectsData = await fetchSubjects();
      setSubjects(subjectsData);
    };
    loadSubjects();
  }, [email, dispatch]); // Add dispatch to dependencies

  return (
    <Container className="mt-4">
      <h1 
        className="font-effect-fire" 
        style={{ textAlign: 'center', margin: 20, fontFamily: 'Sofia', fontWeight: '500', fontSize: '45px' }}
      >
        My Learnings
      </h1>
      <div className="container mt-1">
        <div className="row">
          {myLearnings.length > 0 ? (
            myLearnings.map((sub, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                <SubjectList sub={sub} />
              </div>
            ))
          ) : (
            <p className="text-center text-secondary">
              No subjects found for the selected course and year.
            </p>
          )}
        </div>
      </div>
    </Container>
  );
}

export default MyLearnings;
