import React, { useEffect, useState } from 'react';
import BasicExample from '../components/Courses';
import Button from 'react-bootstrap/Button';
import { useSelector,useDispatch } from 'react-redux';

const fetchCourses = async () => {
  try {
    const url = new URL('https://www.mbascribbles.com:8443/courses'); 
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      alert('Failed to fetch the courses');
      return [];
    }
  } catch (error) {
    console.error('Failed to fetch course', error);
    return [];
  }
};

function Course() {
  const [courses, setCourses] = useState([]);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const accessLevel = useSelector((state) => state.accessLevel);

  useEffect(() => {
    const loadCourses = async () => {
      const coursesData = await fetchCourses();
      console.log(coursesData);
      setCourses(coursesData);
    };
    loadCourses();
  }, []);
  return (
    <div>
      <h1 class="font-effect-fire" style={{ textAlign: 'center',margin:20, fontFamily: "Sofia" ,  fontWeight: '500', fontSize:'45px'}} >Courses Offered</h1>
      <div style={{ textAlign: "right",marginRight:40 }}> 
        {isLoggedIn && accessLevel === 'Admin' &&(
          <Button variant="success">
            <a href="/AddCourse" style={{ textDecoration: "none", color: "white" }}>
            Add Course
          </a>
        </Button>
        )}</div>
      <div className="container">
        <div className="row">
          {courses.map((course, index) => (
            <div key={index} className="col-md-4 mb-4">
              <BasicExample course={course} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Course;
